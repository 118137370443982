import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import Survey from "../../models/survey";
import { api } from "../../store/api";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import SurveyReport from "../../models/surveyReport";
import SurveyReportSchool from "../../models/surveyReportSchool";
import SurveyReportData, { SurveyReportDataDomain, SurveyReportDataDomainStakeholder } from "../../models/surveyReportData";
import { format } from "../../helpers/format";
import { SurveyReportStakeholder } from "../../models/surveyReportStakeholder";
import * as Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsDrilldown from "highcharts/modules/drilldown";
import DomainComparisonComponent from "./report/domainComparisonComponent";
import DomainMatrixAverageComponent from "./report/domainMatrixAverageComponent";
import MetricsByDomain from "./report/metricsByDomain";
import { PermissionTypes } from "../../models/permissionTypes";
import { RequiresPermission } from "../../components/role/requiresRole";

interface SurveyDataRouteParams {
  surveyId?: string;
}
type SurveyDataScreenProps = {
  report?: SurveyReport;
  reportSchool?: SurveyReportSchool;
}
export default function SurveyDataScreen(props: SurveyDataScreenProps) {

  // const dispatch = useAppDispatch();
  // const ui = useAppSelector(state => state.ui.survey);
  // const params = useParams<keyof SurveyDataRouteParams>();
  //const surveyState = useAppSelector(selectSurvey);
  //const survey = surveyState.survey;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [survey, setSurvey] = useState<Survey>();
  const [surveys, setSurveys] = useState<Survey[]>();
  const [report, setReport] = useState<SurveyReport>();
  const [reports, setReports] = useState<SurveyReport[] | undefined>([]);
  const [reportSchools, setReportSchools] = useState<SurveyReportSchool[]>();
  const [reportSchool, setReportSchool] = useState<SurveyReportSchool>();
  const [reportData, setReportData] = useState<SurveyReportData>();
  const [hideSelector, setHideSelector] = useState(false);

  highchartsExporting(Highcharts);
  highchartsDrilldown(Highcharts);
  highchartsExportData(Highcharts)

  useEffect(() => {
    if (props.reportSchool) {
      setSurveys([]);
    }
    else {
      api.surveys.list().then(setSurveys)
        .catch((reason) => {
          setError("Unable to load surveys: " + reason);
        });
    }
  }, [props.reportSchool]);

  useEffect(() => {
    if (report) {
      api.survey(report.surveyId).reports.get(report.id).then((reportDetails) => {
        setReportSchools(reportDetails.schools);
        if (reportDetails.schools?.length === 1) {
          setReportSchool(reportDetails.schools[0]);
        }
      })
        .catch((reason) => {
          setError("Unable to load report schools/systems: " + reason);
        });

    }
    else {
      setReportSchools(undefined);
      setReportSchool(undefined);
    }
  }, [report]);

  const onViewReport = () => {
    if (report?.id && reportSchool?.id) {
      setError(undefined);
      setIsLoading(true);
      setReportData(undefined);
      api.survey(survey!.id).report(report?.id).school(reportSchool?.id).data().then((result) => {
        setIsLoading(false);
        setReportData(result);
      })
        .catch((reason) => {
          setIsLoading(false);
          setError("Unable to load report schools/systems: " + reason);
        });
    }
  };


  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <RequiresPermission permission={PermissionTypes.SurveysList}><li className="breadcrumb-item"><Link to='/surveys'>Surveys</Link></li></RequiresPermission>
        {surveys === undefined && <li className="breadcrumb-item">Loading surveys...</li>}
        {survey ? <li className="breadcrumb-item">{survey.name}</li> : <li className="breadcrumb-item">Select a Survey</li>}
        {survey && report === undefined && <li className="breadcrumb-item">Reports</li>}
        {survey && report && <li className="breadcrumb-item">{report.name}</li>}
        {survey && report && reportSchool === undefined && <li className="breadcrumb-item">Schools/Systems</li>}
        {survey && report && reportSchool && <li className="breadcrumb-item">{reportSchool.name}</li>}
      </ol>
    </div>
    <div className="row">

      {error && <div className="alert alert-danger"><strong>Error</strong> {error}</div>}
      {hideSelector && <div className="col">
        <button className="p-0 btn" onClick={() => setHideSelector(false)}><i className="fas fa-gear"></i></button>
      </div>}

      {!hideSelector && <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none d-flex flex-row justify-content-between align-items-center">
            <h6>Report Parameters</h6>
            <button className="p-0 btn" onClick={() => setHideSelector(true)}><i className="fas fa-eye-slash"></i></button>
          </div>

          {surveys && <RequiresPermission permission={PermissionTypes.SurveysList}><div className="card-body row fw-bold">
            <div className="col-12">
              <h6 className="card-subtitle text-muted">Survey</h6>
              <Typeahead
                options={surveys}
                labelKey="name"
                clearButton={true}
                emptyLabel={'Select a Survey...'}
                multiple={false}
                allowNew={false}
                onChange={(selected) => {
                  //const selectedGradeUnits = selected as GradeUnit[];
                  const selectedSurvey = selected as Survey[];
                  if (selectedSurvey.length > 0) {
                    setSurvey(selectedSurvey[0]);
                  }
                  else {
                    setSurvey(undefined);
                    setReports([]);
                    setReport(undefined);
                  }
                }}
              />
            </div>
          </div>
          </RequiresPermission>
          }

          {survey?.id && <RequiresPermission permission={PermissionTypes.SurveysList}><div className="card-body row fw-bold">
            <div className="col-12">
              <h6 className="card-subtitle text-muted">Report</h6>
              <AsyncTypeahead
                allowNew={false}
                options={reports ?? []}
                id="reportDropDown"
                labelKey={"name"}
                filterBy={['name']}
                selected={report ? [report!] : []}
                minLength={0}
                multiple={false}
                isLoading={reports === undefined}
                clearButton={true}
                onChange={(selected) => {
                  console.warn('onChange', selected);
                  const selectedReports = selected as SurveyReport[];
                  if (selectedReports.length === 1) {
                    setReport(selectedReports[0]);
                  }
                  else {
                    setReport(undefined);
                  }
                }
                }
                onSearch={function (search: string): void {
                  setReports(undefined);
                  api.survey(survey.id).reports.search({ search })
                    .then((partners) => {
                      setReports(partners.records);
                    })
                    .catch((reason) => {
                      throw new Error("Error searching reports: " + reason);
                    });
                }}
              />
            </div>
          </div>
          </RequiresPermission>
          }

          {report && <div className="card-body row fw-bold">
            <div className="col-12">
              <h6 className="card-subtitle text-muted">School/System</h6>
              {reportSchools?.map((school) => <ReportSchoolItem school={school} onSelectSchool={setReportSchool} selectedReportSchool={reportSchool} parentSelected={false} />)}
            </div>
          </div>}

          <div className="card-footer justify-content-end d-flex flex-row">
            <button className="btn btn-sm btn-success" disabled={isLoading || reportSchool === undefined} onClick={() => onViewReport()}>View Report</button>
          </div>
        </div>
      </div>}
      <div className={hideSelector ? "col-md-12" : "col-md-9"}>
        {isLoading && <div className="alert alert-warning"><i className="fa-solid fa-spin fa-spinner"></i> <strong>Loading report data</strong></div>}
        {survey && report && reportSchool && reportData && <ReportDataView report={report} reportSchool={reportSchool} data={reportData} />}
      </div>
    </div>
  </div>);
}
type ReportDataViewProps = {
  //survey: Survey;
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  data: SurveyReportData;
}
export function ReportDataView(props: ReportDataViewProps) {
  const { report, reportSchool, data } = props;

  return <>
    <h3>{data.school.name}</h3>
    {/* <div className="card">
      <div className="card-header">{data.school.name}</div>
      <div className="card-body">

      </div>
    </div> */}

    <div className="card mb-1">
      <div className="card-header">Stakeholders</div>
      <div className="card-body">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-end"># Responses</th>
              <th></th>
              <th className="text-end"># Required</th>
              <th className="text-end">Status</th>
            </tr>
          </thead>
          <tbody>
            <RenderStakeholders surveyReportStakeholders={data.stakeholders} totalResponseCount={data.responseCount} />
            {/* {data.stakeholders?.map((stakeholder) => {
              const responseCount = data.stakeholderResponseCount[`${stakeholder.id}`];
              const isSupressed = responseCount < (stakeholder.minimumResponsesRequired ?? 0);
              return <tr key={stakeholder.id}>
                <td>{stakeholder.name}</td>
                <td className="text-end">{responseCount}</td>
                <td>{format.percentage(responseCount / data.responseCount)}</td>
                <td className="text-end">{stakeholder.minimumResponsesRequired}</td>
                <td className="text-end">{isSupressed ? <><i className="fa-solid fa-triangle-exclamation text-warning"></i> Supressed</> : <>Included</>}</td>
              </tr>;
            })} */}
          </tbody>
          <tfoot>
            <tr>
              <th>Total Responses</th>
              <th className="text-end">{format.number(data.responseCount)}</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>

        </table>

      </div>
    </div>

    {data.domains.map((domain) => <DomainComponent report={report} reportSchool={reportSchool} stakeholders={data.stakeholders} domain={domain} />)}

    {Object.keys(data.metrics).map((domain) => <MetricsByDomain domain={domain} metrics={data.metrics[domain]} report={report} reportSchool={reportSchool} />)}

    {/* <SchoolClimate /> */}
  </>;
}


type RenderStakeholdersProps = {
  surveyReportStakeholders: SurveyReportStakeholder[];
  parentStakeholderId?: number;
  totalResponseCount: number
}
function RenderStakeholders(props: RenderStakeholdersProps) {
  const { parentStakeholderId } = props;
  const stakeholders = props.surveyReportStakeholders.filter(s => parentStakeholderId ? s.surveyStakeholder!.parentSurveyStakeholderId === parentStakeholderId : s.surveyStakeholder!.parentSurveyStakeholderId === undefined);
  //const surveyReportStakeholder = props.surveyReportStakeholders.find(srs => srs.surveyStakeholderId == )

  if (stakeholders.length === 0) {
    return <></>; // no stakeholders
  }

  return <>{stakeholders?.map((stakeholder) => {

    const surveyReportStakeholder = props.surveyReportStakeholders.find((srs) => srs.surveyStakeholderId === stakeholder.id);
    if (surveyReportStakeholder) {
      return <RenderStakeholder stakeholder={stakeholder} {...props} key={stakeholder.id} />;
      // return <><tr key={stakeholder.id}>
      //   <td>{props.parentStakeholderId && <i className="ms-2 fa-solid fa-arrow-turn-down-right"></i>} {stakeholder.name}</td>
      //   <td className="text-end">{responseCount}</td>
      //   <td>{format.percentage(responseCount / totalResponseCount)}</td>
      //   <td className="text-end">{surveyReportStakeholder.minimumResponsesRequired}</td>
      //   <td className="text-end">{isSupressed ? <><i className="fa-solid fa-triangle-exclamation text-warning"></i> Supressed</> : <>Included</>}</td>
      // </tr>
      //   {!isSupressed && <RenderStakeholders surveyReportStakeholders={props.surveyReportStakeholders} stakeholderResponseCount={stakeholderResponseCount} totalResponseCount={totalResponseCount} parentStakeholderId={stakeholder.id} />}
      // </>;
    }
    return <></>;
  })}

  </>;
}
type RenderStakeholderProps = {
  stakeholder: SurveyReportStakeholder;
}
function RenderStakeholder(props: RenderStakeholdersProps & RenderStakeholderProps) {
  const { stakeholder, totalResponseCount } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const responseCount = stakeholder.responseCount ?? 0;
  const isSupressed = (stakeholder.isSupressed ?? false)
    || !(stakeholder.isEnabled ?? false)
    || responseCount < (stakeholder.minimumResponsesRequired ?? 0);
  const hasSubStakeholders = true && props.surveyReportStakeholders.find((srs) => srs.surveyStakeholder?.parentSurveyStakeholderId === stakeholder.surveyStakeholderId);

  return <><tr key={stakeholder.id}>
    <td>
      {hasSubStakeholders ? <button className="btn btn-sm p-1" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded && <i className="fa-fw fa-solid fa-square-minus"></i>}
        {!isExpanded && <i className="fa-fw fa-solid fa-square-plus"></i>}
      </button>
        : <i className="fa fa-fw"></i>}
      {props.parentStakeholderId && <i className="mx-2 fa-solid fa-arrow-turn-down-right"></i>}
      {stakeholder.name}
    </td>
    <td className="text-end">{responseCount}</td>
    <td>{format.percentage(responseCount / totalResponseCount)}</td>
    <td className="text-end">{stakeholder.minimumResponsesRequired}</td>
    <td className="text-end">{isSupressed ? <><i className="fa-solid fa-triangle-exclamation text-warning"></i> Supressed</> : <>Included</>}</td>
  </tr>
    {isExpanded && <RenderStakeholders surveyReportStakeholders={props.surveyReportStakeholders} totalResponseCount={totalResponseCount} parentStakeholderId={stakeholder.id} />}
  </>;
}

type DomainComponentProps = {
  //survey: Survey;
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  stakeholders: SurveyReportStakeholder[];
  domain: SurveyReportDataDomain;
}
function DomainComponent(props: DomainComponentProps) {
  const { report, reportSchool, stakeholders, domain } = props;
  return <div key={domain.domain} className="my-3">
    <h3 className="text-center">{domain.domain}</h3>
    {domain.stakeholders
      //.filter((s) => s.)
      .map((domainStakeholder) => <DomainStakeholderComponent domainStakeholder={domainStakeholder} />)}
    {domain.comparisons?.map((comparison) => <DomainComparisonComponent key={`${domain.domain}_${comparison.subDomain}`} report={report} reportSchool={reportSchool} stakeholders={stakeholders} comparison={comparison} />)}
    {domain.matrixAverages?.map((comparison) => <DomainMatrixAverageComponent key={`${domain.domain}_${comparison.subDomain}`} report={report} reportSchool={reportSchool} stakeholders={stakeholders} comparison={comparison} />)}
  </div>;
}


type DomainStakeholderComponentProps = {
  domainStakeholder: SurveyReportDataDomainStakeholder;
}
function DomainStakeholderComponent(props: DomainStakeholderComponentProps) {
  const { domainStakeholder } = props;

  const [isOpen, setIsOpen] = useState(false);

  return <div className="card my-1" key={domainStakeholder.stakeholder}>
    <div className="card-header d-flex justify-content-between">
      <h4 className="text-center">{domainStakeholder.stakeholder}</h4>
      {!isOpen && <button className="btn btn-sm btn-outline-primary" onClick={() => setIsOpen(true)}><i className="fa-solid fa-plus"></i></button>}
      {isOpen && <button className="btn btn-sm btn-outline-primary" onClick={() => setIsOpen(false)}><i className="fa-solid fa-minus"></i></button>}
    </div>
    <div className={"card-body collapse " + (isOpen ? 'show' : '')}>
      {domainStakeholder.subDomains.map((subdomain) => {
        const total = subdomain.choices.reduce((partialSum, a) => partialSum + a.count, 0);
        return <div key={subdomain.subDomain}>
          <h4>{subdomain.subDomain}</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Choice</th>
                <th># Responses</th>
                <th>% Responses</th>
              </tr>
            </thead>
            <tbody>
              {subdomain.choices.map((choice) => <tr key={choice.choiceText}>
                <td>{choice.choiceText}</td>
                <td>{format.number(choice.count)}</td>
                <td>{total > 0 && format.percentage(choice.count / total)}</td>
              </tr>)}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{total}</th>
                <th></th>
              </tr>
            </tfoot>
          </table></div>;
      })}
    </div>
  </div>;
}

type ReportSchoolItemProps = {
  school: SurveyReportSchool;
  selectedReportSchool?: SurveyReportSchool;
  onSelectSchool: (school: SurveyReportSchool) => void;
  parentSelected: boolean;
}
export function ReportSchoolItem(props: ReportSchoolItemProps) {
  const { school, onSelectSchool, selectedReportSchool, parentSelected } = props;

  const isSelected = school === selectedReportSchool;

  return <div key={school.id}>
    <div className="form-check mb-2 form-check-inline">
      <label className="form-check-label">
        <input
          className={"form-check-input " + (parentSelected ? ' bg-success' : '')}
          type="radio"
          //{...register(`attributes.${props.index}.choicesValue` as const)}
          //value={choice.id}
          //checked={selectedChoices?.includes(choice.id!) ?? false}
          //checked={choicesField.value?.includes(choice.id!) ?? false}
          checked={isSelected}
          onChange={(e) => {
            onSelectSchool(school);
            //setSelectedChoices([parseInt(e.currentTarget.value)]);
            //choicesField.onChange([parseInt(e.currentTarget.value)]);
          }}
        />
        {school.name}
      </label>
    </div>
    <div className="ms-3">
      {school.schools?.map((subschool) => <ReportSchoolItem school={subschool} onSelectSchool={onSelectSchool} selectedReportSchool={selectedReportSchool} parentSelected={parentSelected || isSelected} />)}
    </div>
  </div>
  //return <></>;
}