import { PermissionTypes, roleHasPermission } from "../models/permissionTypes";
import Roles, { RoleTypes } from "../models/roles";
import { UserCreatedRecord } from "../models/types";
import { useAppSelector } from "../store/hooks";
import { selectSession } from "../store/sessionSlice";

// export function recordIsMine(record: UserCreatedRecord): boolean {
//   const session = useAppSelector(selectSession);
//   return record.userId === session.id;
// };
type HasPermissionTypes = {
  isMine: (record: UserCreatedRecord) => boolean;
  hasPermission: (permission: PermissionTypes) => boolean;
};
export function usePermissions(): HasPermissionTypes {
  const session = useAppSelector(selectSession);
  return {
    isMine: (record: UserCreatedRecord) => record.userId === session.id,
    hasPermission: (permission: PermissionTypes) => ((session.roles?.findIndex((userRole) => roleHasPermission(userRole as RoleTypes, permission)) ?? -1) >= 0)
  };

}

